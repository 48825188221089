<template>
  <v-app>
    <div class="form-signin-wrap">
      <div class="form-signin">
        <div class="container">
          <div class="auth-img-wrap">
            <img :src="STATIC.appLogoUrl" class="auth-img" alt="">
          </div> 
          <div class="form-group">
            <div class="text-center">
              <h3 class="font-weight-light">Восстановление доступа</h3>
            </div>
            <div v-show="step == 'recovery-form'">
              <h6 class="font-weight-light text-center mb-4">Восстановление доступа пациента</h6>
              <div class="form-group">
                <v-form ref="form" v-model="recoveryForm.validRegisterForm" onsubmit="return false;">
                  <v-text-field
                    v-model="recoveryForm.login"
                    :counter="18"
                    :rules="[rules.login]"
                    label="Телефон"
                    name="register-login"
                    rounded
                    type="tel"
                    maxlength="20"
                    autocomplete="off"
                    autofocus
                    required
                    outlined
                    dense
                    v-mask="'+7 (###) ###-##-##'"
                  ></v-text-field>
                  <v-menu
                    ref="menu"
                    v-model="birthdayMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    rounded="lg"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="recoveryForm.birthday"
                        :rules="[rules.birthday]"
                        label="Дата рождения"
                        name="register-birthday"
                        v-mask="'####-##-##'"
                        rounded
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        required
                        dense
                        readonly
                      ></v-text-field>
                    </template>
                    <v-card class="pa-3" rounded="lg">
                      <div>
                        <v-text-field
                          outlined
                          rounded
                          dense
                          label="Год"
                          type="number"
                          v-model="birthdayYear"
                          :rules="[
                            val => (val && +val > 1900) ? true : 'Введите корректный год. (4 цифры года рождения)',
                            val => (val && +val === Math.floor(+val) ? true : 'Введите корректный год. (4 цифры года рождения)'),
                            val => (val && val.indexOf('.') !== -1) ? 'Введите корректный год. (4 цифры года рождения)' : true,
                          ]"
                          v-mask="'####'"
                        >
                        </v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          outlined
                          rounded
                          dense
                          label="Месяц"
                          type="number"
                          v-model="birthdayMonth"
                          :rules="[
                            val => (val && +val >= 1 && +val <= 12) ? true : 'Введите корректный номер месяца. (1 или 2 цифры номера месяца)',
                            val => (val && +val === Math.floor(+val) ? true : 'Введите корректный номер месяца. (1 или 2 цифры номера месяца)'),
                            val => (val && val.indexOf('.') !== -1) ? 'Введите корректный номер месяца. (1 или 2 цифры номера месяца)' : true,
                          ]"
                          v-mask="'##'"
                        >
                        </v-text-field>
                      </div>
                      <div>
                        <v-text-field
                          outlined
                          rounded
                          dense
                          label="День"
                          type="number"
                          v-model="birthdayDay"
                          :rules="[
                            val => (val && +val >= 1 && +val <= 31) ? true : 'Введите корректный день. (1 или 2 цифры день)',
                            val => (val && +val === Math.floor(+val) ? true : 'Введите корректный день. (1 или 2 цифры день)'),
                            val => (val && val.indexOf('.') !== -1) ? 'Введите корректный день. (1 или 2 цифры день)' : true,
                          ]"
                          v-mask="'##'"
                        >
                        </v-text-field>
                      </div>
                    </v-card>
                  </v-menu>
                  <div class="d-flex align-center justify-space-between">
                    <v-btn 
                      :loading="loading" 
                      :dark="recoveryForm.validRegisterForm" 
                      :disabled="!recoveryForm.validRegisterForm" 
                      rounded
                      color="primary"
                      block
                      @click="checkRecoveryForm"
                    >Далее</v-btn>
                  </div>
                </v-form>
              </div>
            </div>
            <div v-show="step == 'code-input'">
              <h6 class="font-weight-light text-center mb-4">Мы позвоним вам на номер <br><b>{{this.recoveryForm.login}}</b>, чтобы сообщить вам четырехзначный код подтверждения</h6>
                <div class="form-group">
                  <v-form ref="form" v-model="codeForm.validCodeForm" onsubmit="return false;">
                    <v-text-field
                      v-model="codeForm.code"
                      :rules="[rules.code]"
                      autocomplete="off"
                      autofocus
                      :disabled="codeForm.timeOut == 0" 
                      label="Код подтверждения"
                      required
                      rounded
                      maxlength="4"
                      :counter="4"
                      v-mask="'####'"
                      outlined
                      dense
                    ></v-text-field>
                    <div class="d-flex align-center justify-space-between">
                      <v-btn 
                        :loading="loading" 
                        :dark="codeForm.validCodeForm && (codeForm.timeOut > 0)" 
                        :disabled="!codeForm.validCodeForm || !codeForm.timeOut" 
                        rounded
                        color="primary"
                        block
                        @click="checkCode"
                      >Далее</v-btn>
                    </div>
                    <div class="mt-2 text-center" v-if="codeForm.timeOut >= 0">
                      <small v-if="codeForm.timeOut > 0">Код действителен в течение {{codeForm.timeOut}} секунд</small>
                      <v-btn 
                        v-if="codeForm.timeOut == 0  && !loading"
                        small 
                        :dark="codeForm.repeatTimeOut == 0"
                        rounded
                        color="primary"
                        block
                        link
                        :disabled="codeForm.repeatTimeOut > 0"
                        @click="checkRecoveryForm"
                      >Выслать повторно код {{codeForm.repeatTimeOut ? '(' + codeForm.repeatTimeOut + ')' : ''}}
                      </v-btn>
                    </div>
                  </v-form>
                </div>
              </div>
              <div v-show="step == 'password-input'">
                <h6 class="font-weight-light text-center mb-4">Придумайте пароль для входа</h6>
                <v-form ref="form" v-model="passwordForm.validPasswordForm" onsubmit="return false;">
                  <v-text-field
                    v-model="passwordForm.password"
                    :rules="[rules.password]"
                    label="Пароль"
                    name="recovery-password"
                    autofocus
                    required
                    rounded
                    outlined
                    dense
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                  ></v-text-field>
                  <v-text-field
                    v-model="passwordForm.passwordRepeat"
                    :rules="[rules.passwordRepeat]"
                    label="Повторите пароль"
                    required
                    rounded
                    outlined
                    dense
                    name="recovery-repeat-password"
                    autocomplete="off"
                    :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPasswordRepeat = !showPasswordRepeat"
                    :type="showPasswordRepeat ? 'text' : 'password'"
                  ></v-text-field>
                  <div class="d-flex align-center justify-space-between">
                    <v-btn 
                      :loading="loading" 
                      :dark="passwordForm.validPasswordForm" 
                      :disabled="!passwordForm.validPasswordForm" 
                      rounded
                      color="primary"
                      block
                      @click="checkPasswords"
                    >Далее</v-btn>
                  </div>
                </v-form>
              </div>
              <div v-show="step == 'agreed-documents'">
                <h6 class="font-weight-light text-center mb-4">Примите следующие документы</h6>
                <div class="form-group">
                  <small>
                    <v-icon small class="mr-2">fas fa-check</v-icon> <router-link to="/agreement">Согласие на обработку персональных данных</router-link><br>
                    <v-icon small class="mr-2">fas fa-check</v-icon> <router-link to="/agreement">Публичный договор-оферта</router-link><br>
                    <v-icon small class="mr-2">fas fa-check</v-icon> <router-link to="/agreement">Информированное добровольное согласие на медицинские вмешательства</router-link><br>
                    <v-icon small class="mr-2">fas fa-check</v-icon> <router-link to="/agreement">Уведомления о последствиях несоблюдения рекомендаций медицинской организации</router-link><br>
                  </small>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <v-btn 
                    :loading="loading" 
                    dark
                    rounded
                    color="primary"
                    block
                    @click="agreedDocuments"
                  >Принять</v-btn>
                </div>
              </div>
            </div>
            <div class="form-group text-center text-muted">
              <small><router-link to="/login">Вернутся назад</router-link></small>
            </div>
            <div class="form-group text-center text-muted">
              <small>{{year}} © Киберсофт.Здоровье</small>
            </div>       
          </div>
        </div>
      </div>
    <v-dialog v-model="recoveryForm.errorDialog" max-width="400">
      <v-card>
        <v-card-title>Восстановление доступа</v-card-title>
        <v-card-text>
          <p>Не удалось выполнить восстановление доступа. {{this.recoveryForm.errorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="recoveryForm.errorDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="codeForm.errorDialog" max-width="400">
      <v-card>
        <v-card-title>Восстановление доступа</v-card-title>
        <v-card-text>
          <p>{{this.codeForm.errorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="codeForm.errorDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="passwordForm.errorDialog" max-width="400">
      <v-card>
        <v-card-title>Восстановление доступа</v-card-title>
        <v-card-text>
          <p>Не удалось установить пароль. {{this.passwordForm.errorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="passwordForm.errorDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Recovery',
  components: {},
  data: function() {
    return {
      step: 'recovery-form',
      newCustomerId: 0,
      registerToken: '',
      changePasswordToken: '',
      agreedDocumentsToken: '',
      codeValidate: false,
      repeatCodeTimeOut: 0,
      codeTimeOut: 0,
      birthdayDay: null,
      birthdayMonth: null,
      birthdayYear: null,
      recoveryForm: {
        login: '',
        birthday: null,
        validRecoveryForm: false,
        errorText: '',
        errorDialog: false
      },
      passwordForm: {
        password: '',
        passwordRepeat: '',
        validPasswordForm: false,
        errorText: '',
        errorDialog: false
      },
      codeForm: {
        code: '',
        validCodeForm: false,
        errorText: '',
        errorDialog: false,
        timeOut: false,
        repeatTimeOut: false
      },
      showPassword: false,
      showPasswordRepeat: false,
      birthdayMenu: false,
      authError: false,
      loading: false,
      year: new Date().getFullYear(),
      rules: { 
          birthday: value => {
              if (!value){
                return 'Введите дату рождения'
              }
              console.log(value);
              if (value){
                  let years = this.$moment().diff(this.$moment(value, 'YYYY-MM-DD'), 'years');
                  if (!this.$moment(value, 'YYYY-MM-DD').isValid()){
                    return 'Неверный формат даты рождения'
                  }
                  if (years < 18){
                    return 'Восстановление доступа возможно только для совершеннолетних пациентов'
                  } 
                  return true;
              }
          },
          login: value => {
            if (!value){
              return 'Введите телефон'
            }
            if (value && value.length < 18) {
              return 'Телефон должен быть не меньше 10 цифр';
            }
            return true;
          },
          code: value => {
            if (!value) {
              return 'Введите код подтверждения';
            }
            return true;
          },
          password: value => {
            if (!value) {
              return 'Введите пароль';
            }
            if (value.length < 8 || value.length > 20){
              return 'Длинна пароля должна быть от 8 до 20 символов';
            }
            if (value.search(/[a-z]/i) < 0) {
              return  'Пароль должен содержать хотя бы одну букву';
            }
            if (value.search(/[0-9]/) < 0) {
              return 'Пароль должен содержать хотя бы одну цифру'; 
            }
            if (value.search(/[а-яА-ЯёЁ]/) >= 0) {
              return 'Пароль должен содержать цифры и символы латинского алфавита'; 
            }
            return true;
          },
          passwordRepeat: value => {
            if (!value) {
              return 'Повторите пароль';
            }
            if (value != this.passwordForm.password) {
              return 'Пароли не совпадают';
            }
            return true;
          },
      },
    }
  },
  watch: {
    birthdayMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  
    birthdayDay(val) {
      if (!this.birthdayYear || !this.birthdayMonth) {
        return;
      }
      const month = (this.birthdayMonth.toString().length === 1) ? `0${this.birthdayMonth}` : this.birthdayMonth;
      const day = (val.toString().length === 1) ? `0${val}` : val;
      this.recoveryForm.birthday = `${this.birthdayYear}-${month}-${day}`;
    },
  
    birthdayMonth(val) {
      if (!this.birthdayYear) {
        return;
      }
      const month = (val.toString().length === 1) ? `0${val}` : val;
      this.recoveryForm.birthday = `${this.birthdayYear}-${month}`;
      if (this.birthdayDay) {
        const day = (this.birthdayDay.toString().length === 1) ? `0${this.birthdayDay}` : this.birthdayDay;
        this.recoveryForm.birthday += `-${day}`;
      }
    },
  
    birthdayYear(val) {
      this.recoveryForm.birthday = `${val}`;
      if (this.birthdayMonth) {
        const month = (this.birthdayMonth.toString().length === 1) ? `0${this.birthdayMonth}` : this.birthdayMonth;
        this.recoveryForm.birthday += `-${month}`;
        if (this.birthdayDay) {
          const day = (this.birthdayDay.toString().length === 1) ? `0${this.birthdayDay}` : this.birthdayDay;
          this.recoveryForm.birthday += `-${day}`;
        }
      }
    },
  },
  mounted () {
    setInterval(() =>{
      // Если время кода идет
      if (this.codeForm.timeOut > 0){
        --this.codeForm.timeOut;
      } 
      // Если время повтора кода идет
      if (this.codeForm.repeatTimeOut > 0){
        --this.codeForm.repeatTimeOut;
      }
    }, 1000);
    
    if (!this.recoveryForm.login) {
      let url = new URL(window.location.href);
      let phone = url.searchParams.get('phone');
      if (phone) {
        phone = phone.replaceAll(' ', '').replaceAll('7(', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
        url.searchParams.delete('phone');
        this.recoveryForm.login = phone;
        window.history.replaceState({}, document.title, url);
      }
    }
  },
  methods: {
    ...mapActions([
        'login'
    ]),
    checkRecoveryForm() {
      this.loading = true;
      this.codeForm.code = '';
      let data = new FormData();
      data.append('Recovery[birthday]', this.recoveryForm.birthday);
      data.append('Recovery[login]', this.recoveryForm.login);
      fetch(
        window.config.apiUrl + '/recovery/get-recovery-code', {
        method: 'POST',
        body: data
      }).then(response => response.json())
      .then((response) => {
        console.log(response);
        this.loading = false;
        if (response.status == 'success'){
          this.step = 'code-input';
          this.recoveryToken = response.token;
          this.codeForm.timeOut = response.codeTimeOut;
          this.codeTimeOut = response.codeTimeOut
          this.repeatCodeTimeOut = response.repeatCodeTimeOut
          this.codeForm.repeatTimeOut = this.repeatCodeTimeOut;
        } else {
          this.recoveryForm.errorDialog = true;
          this.recoveryForm.errorText = response.errorText;
        }
      });  
    },
    checkCode() {
      this.loading = true;
      let data = new FormData();
      data.append('code', this.codeForm.code);
      data.append('token', this.recoveryToken);
      data.append('Recovery[birthday]', this.recoveryForm.birthday);
      data.append('Recovery[login]', this.recoveryForm.login);
      fetch(
        window.config.apiUrl + '/recovery/check-code', {
        method: 'POST',
        body: data
      }).then(response => response.json())
      .then((response) => {
        console.log(response);
        this.loading = false;
        if (response.status == 'success'){
          // Если пользователь существует то авторизуем
          if (response.authToken){
            localStorage.setItem('token', response.authToken)
            localStorage.setItem('idUser', response.idUser)
            localStorage.setItem('activeUser', response.idUser)
            window.location = '/';
          } else {
            // Предлагаем создать пароль
            this.step = 'password-input';
            this.newCustomerId = response.customerId;
            this.changePasswordToken = response.changePasswordToken;
          }
        } else {
          this.codeForm.errorDialog = true;
          this.codeForm.errorText = response.errorText;
        }
      });  
    },
    checkPasswords () {
      this.loading = true;
      let data = new FormData();
      data.append('password', this.passwordForm.password);
      data.append('token', this.changePasswordToken);
      data.append('idCustomer', this.newCustomerId);
      fetch(
        window.config.apiUrl + '/recovery/set-password', {
        method: 'POST',
        body: data
      }).then(response => response.json())
      .then((response) => {
        console.log(response);
        this.loading = false;
        if (response.status == 'success'){
          this.step = 'agreed-documents';
          this.agreedDocumentsToken = response.agreedDocumentsToken;
        } else {
          this.passwordForm.errorDialog = true;
          this.passwordForm.errorText = response.errorText;
        }
      }); 
    },
    agreedDocuments() {
      this.loading = true;
      let data = new FormData();
      data.append('idCustomer', this.newCustomerId);
      data.append('token', this.agreedDocumentsToken);
      fetch(
        window.config.apiUrl + '/recovery/agreed-documents', {
        method: 'POST',
        body: data
      }).then(response => response.json())
      .then((response) => {
        console.log(response);
        if (response.status == 'success'){
          let authParams = {
            login: this.recoveryForm.login,
            password: this.passwordForm.password
          };
          this.login(authParams)
          .then(() => {
            window.location = '/';
          })
          .catch(err => {
            console.log(err)
            this.loading = false;
            this.agreedForm.errorDialog = true;
            this.agreedForm.errorText = 'Не удалось авторизировать пользователя';
          })
        } else {
          this.loading = false;
          this.agreedForm.errorDialog = true;
          this.agreedForm.errorText = response.errorText;
        }
      }); 
    },
    save (date) {
      this.$refs.menu.save(date)
    },
  },
  computed: {
    ...mapGetters([
      'LOGIN_PHONE',
      'STATIC'
    ])
  }
}
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.form-signin-wrap{
  display: flex;
  height: 100%;
}

.register-animate-enter-active {
  animation: coming .6s;
  animation-delay: .8s;
  opacity: 0;
}

.register-animate-leave-active {
  animation: going .6s;
}

.auth-img{
  margin: auto;
  height: 80px;
  object-fit: contain;
  margin-bottom: 10px;
  pointer-events: none;
}

@keyframes going {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

</style>
