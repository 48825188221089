<template>
  <div class="footer-mobile">
    <v-navigation-drawer app v-model="drawer" right>
      <v-list nav>
        <v-list-item-group active-class="primary--text text--accent-4">
          <v-list-item two-line to="/information"  class="text-decoration-none">
            <v-list-item-avatar>
              <img src="@/assets/layout/avatar.png" alt="">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{getActiveUserName}}</v-list-item-title>
              <v-list-item-subtitle>Профиль</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div class="py-2" v-if="user.groups && user.groups.length" style="overflow-x: auto">
            <div class="d-flex flex-nowrap pb-1" style="width: max-content">
              <template v-for="group in user.groups">
                <v-chip small :color="group.color" :key="group.name">
                  <v-icon
                    v-if="group.icon"
                    color="currentColor"
                    left
                    x-small
                  >
                  {{group.icon}}
                  </v-icon>
                  {{group.name}}
                </v-chip>
              </template>
            </div>
          </div>
          <v-list-item class="text-decoration-none" link to="/information" active-class="primary--text text--accent-4">
            <v-list-item-title>Профиль</v-list-item-title>
          </v-list-item>
          <v-list-item class="text-decoration-none" link to="/payment-process">
            <v-list-item-title>Процесс оплаты</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout()"> 
            <v-list-item-title>
              Выйти
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-bottom-navigation app temporary color="primary">
      <v-btn to="/" class="text-decoration-none">
        <v-icon>fas fa-home</v-icon>
      </v-btn>
      <v-btn to="/appointments" class="text-decoration-none">
        <v-icon>far fa-calendar-check</v-icon>
      </v-btn>
      <v-btn to="/appointment" class="text-decoration-none">
        <v-icon>far fa-calendar-plus</v-icon>
      </v-btn>
      <v-btn to="/chat" v-if="STATIC.appUseTelemedicine" @click="$store.dispatch('setCurrentCouponService', null);" class="text-decoration-none"> 
        <v-badge :content="this.UNREAD_COUNT" :value="this.UNREAD_COUNT" color="light-green lighten-1" overlap depressed bordered>
          <v-icon>far fa-comment-dots</v-icon>
        </v-badge>
      </v-btn>
      <v-btn @click.stop="drawer = !drawer" style="max-width: 60px">
        <v-badge avatar overlap bordered v-if="getUser.groups && getUser.groups.length && getUser.groups[0]">
          <template v-slot:badge >
            <v-avatar :color="getUser.groups[0].color" :title="getUser.groups[0].name">
              <v-icon x-small>{{ getUser.groups[0].icon }}</v-icon>
            </v-avatar>
          </template>
  
          <v-avatar size="30">
            <v-icon  color="grey darken-1">far fa-user-circle</v-icon>
          </v-avatar>
        </v-badge>
        <template v-else>
          <v-avatar size="30">
            <v-icon  color="grey darken-1">far fa-user-circle</v-icon>
          </v-avatar>
        </template>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>

import eventBus from '../../eventBus';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
      ...mapGetters([
      'getRepresentatives',
      'getActiveUserName',
      'UNREAD_COUNT',
      'STATIC',
      'getUser'
    ]),
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    logout: function() {
      localStorage.removeItem('login');
      localStorage.removeItem('password');
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    goToBack() {
      eventBus.$emit('goToBack');
    },
  }
}

</script>

<style scoped>

.footer-mobile{
  display: none;
}

@media screen and (max-width: 1264px){
  .footer-mobile{
    display: block;
  }
}

</style>


<style >

.footer-mobile{
  display: none;
}

@media screen and (max-width: 380px){
  .footer-mobile .v-item-group.v-bottom-navigation .v-btn {
    min-width: 70px;
  }
}

@media screen and (max-width: 360px){
  .footer-mobile .v-item-group.v-bottom-navigation .v-btn {
    min-width: 60px;
  }
}

</style>


