<template>
  <div>
    <v-card 
      flat
      class="appointmet-info-wrapper mb-2"
      max-width="700"
    >
      <div class="appointmet-info" :class="[min ? 'min' : '']">
        <div class="wrap pb-1 pt-1 date">
          <i class="icon far fa-calendar-alt"></i>
          <span> Дата: <b>{{formatDate}}</b> </span>
        </div>
        <div class="wrap pb-1 time" v-if="time">
          <i class="icon far fa-clock"></i>
          <span> Время: <b>{{time}}</b> </span>
        </div>
        <div class="wrap pb-1 address" v-if="address">
          <i class="icon fas fa-map-marker-alt"></i>
          <span class="date"> Адрес: <b>{{address}}</b> </span>
        </div>
      </div>
    </v-card>

    <v-card 
      elevation="2"
      class="appointmet-info-wrapper"
      max-width="700"
    >
      <div class="user-info">
        <div class="avatar">
          <v-img 
            max-height="150"
            max-width="150"
            min-height="150"
            min-width="150"
            :src="getSrc(user.id)"
            alt="Специалист"
          ></v-img>
        </div>
        <div class="description">
          <div v-if="shortName(user)">
            <h5 style="word-break: break-word;">{{user.surname}} {{user.name}} {{user.middleName}}</h5>
          </div>
          <div v-else>
            <h6 style="word-break: break-word;">{{user.surname}} {{user.name}} {{user.middleName}}</h6>
          </div>
          <div v-if="user.specials">
            <span v-for="(special, index) in user.specials" :key="index">
              {{special}}<span v-if="index!==user.specials.length-1">,</span> 
            </span>
          </div>
          <div v-if="user.userCategory">
            <span class="user-category">
              Категория - <b>{{user.userCategory}}</b>
            </span>
          </div>
          <div v-if="user.workExperience">
            <span class="user-exp">
              Стаж - <b>{{getExp(user.workExperience)}}</b>
            </span>
          </div>
          <div v-if="user.scientificAchievements">
            <span class="user-ach">
              <b>{{user.scientificAchievements}}</b>
            </span>
          </div>
        </div>
      </div>

      <div v-if="user.description" class="more-info pl10" @click="showInfo">
        <p v-if="hideInfo" style="user-select:none">
          Подробнее о специалисте 
          <i class="fas fa-angle-down"></i>
        </p>
        <p v-else style="user-select:none">
          Скрыть описание 
          <i class="fas fa-angle-up"></i>
        </p>
        <span v-html="user.description" :class="[hideInfo ? 'd-none' : '']"></span>
      </div>

      <div class="services-select px-3">
        <p v-if="this.services && this.services.length > 0" @click="showServices">
          {{selectedServices}}<br>
          <b >Сумма: {{price}} руб. </b>
          <i class="fas fa-angle-down" v-if="hideServices"></i>
          <i class="fas fa-angle-up" v-else></i>
        </p>
        <p v-else-if="hideServices" @click="showServices" style="user-select:none">
          Выбрать услуги
          <i class="fas fa-angle-down"></i>
        </p>
        <p v-else @click="showServices" style="user-select:none">
          Скрыть услуги 
          <i class="fas fa-angle-up"></i>
        </p>

        <div class="services-list" :class="[hideServices ? 'd-none' : '']">
          <div class="services-list-item no-service"
            @click="serviceSelectHandel(null)"
          >
            Без услуги
          </div>
          <div 
            class="services-list-item" 
            v-for="service in user.services" 
            :key="service.id"
          >
            <div>
              <v-checkbox 
                :name="'cb_' + service.id" 
                :value="service" 
                v-model="services"
                class="w-100"
                style="font-size: 0.3rem"
                v-on:change="serviceSelectHandel(service)"
                hide-details
              >
                <template v-slot:label>
                  <div>
                    <span>{{service.nameService}}</span><br>
                    <b>{{service.priceService / 100}} руб.</b>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </div>

      <v-alert color="grey lighten-3" icon="fas fa-info-circle" border="left">
        {{ STATIC.appointmentNotification }}
      </v-alert>
      <v-btn
        color="primary"
        block
        depressed
        @click="createPayment()"
        :loading="loadingCreateAppointment"
      >
        <span>
          Записаться
        </span>
      </v-btn>
      
    </v-card>

    <v-dialog persistent v-model="createLinkAppointmentDialog" max-width="400">
      <v-card>
        <v-card-title>Создание записи</v-card-title>
        <v-card-text class="">
          <div v-if="this.loadingCreateAppointment" class="text-center mt-5">
            <v-progress-circular
              :size="50"
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <p v-if="this.createLinkAppointmentErrorText">{{this.createLinkAppointmentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-if="!this.loadingCreateAppointment" @click="closeCreateDialog" rounded color="primary" dark>Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import router from '../../router'

export default {
  data(){
    return {
      user: {},
      hideInfo: true,
      hideServices: true,
      service: '',
      services: [],
      createLinkAppointmentDialog: false, // Диалог создания записи по ссылке
      loadingCreateAppointment: false,
      createLinkAppointmentErrorText: '',
      showPromoInput: false
    }
  },
  props: {
    appToken: String,
  },
  created(){
    // получение данных о выбранном специалисте
    this.getData()
    // установка услуги, если есть
    if (this.serviceActive) this.service = this.serviceActive
  },
  computed: {
    ...mapGetters(['date', 'time', 'address', 'userId', 'serviceActive', 'token', 'STATIC']),
    // форматирование даты для отображения
    formatDate(){
      return moment(this.date).locale('ru').format('Do MMM')
    },
    min(){
      if (this.$mq === 'sm') return true
      return false
    },
    price(){
      return this.services.reduce( (prev, cur) => prev + cur.priceService, 0) / 100;
    },
    selectedServices() {
      if (!this.services || !this.services.length) 
        return 'Услуги не выбраны';
      if (this.services.length == 1) 
        return this.services[0].nameService;
      return `Выбрано услуг: ${this.services.length}`;
    },
    btnIsPaymentRequired() {
      if (!this.services || !this.services.length) {
        return false;
      }
      return true;
    }
  },
  methods: {
    // получение данных о выбранном специалисте
    async getData(){
      const dateTo = ''
      const showFreeIntervals = false
      const showUserServices = true
      await this.$store.dispatch('getUser', {dateTo, showFreeIntervals, showUserServices})
        .then(res => {
          this.user = res
          })
    },
    // изменение состояния отображения подробной информации о враче
    showInfo(){
      this.hideInfo = !this.hideInfo
    },
    // изменение состояния отображения услуг
    showServices(){
      if (this.appToken) return;
      this.hideServices = !this.hideServices
    },
    // обработчик выбора услуги
    serviceSelectHandel(service){
      if (service) {
        service.isChecked = true;
        const data = {name: service.nameService, id: service.id, price: service.priceService}
        let oldServices = this.$store.getters.service ?? [];
        const oldValIndex = oldServices.findIndex( val => val.id == data.id );
        // При повторном клике убираем услугу из выбраных
        let newServices;
        if (oldValIndex !== -1) {
          oldServices.splice(oldValIndex, 1);
          newServices = [...oldServices];
        } else {          
          newServices = [...oldServices, data];
        }
        this.$store.commit('setService', newServices);
        localStorage.service = JSON.stringify(newServices);
        this.service = service;
      } else {
        this.$store.commit('setService', null)
        localStorage.service = null
        this.services = []
      }
    },
    // получение стажа работы врача
    getExp(date){
      const years =  moment(date, "YYYYMMDD").locale('ru').fromNow();
      return years.split(' ').splice(0,2).join(' ')
    },
    createAppointment(){
      if (this.userId && this.date && this.time) {
        this.loadingCreateAppointment = true

        const customerSource = localStorage.getItem('customerSource');
        localStorage.removeItem('customerSource');
        
        this.$http.post(window.config.apiUrl + '/appointment/create-appointment', {
          idUser: this.userId,
          dateTime: this.date + ' ' + this.time,
          services: (this.serviceActive ? (this.serviceActive.id) : ''),
          idCustomer: this.$store.getters.activeUser,
          token: this.appToken || this.token,
          source: customerSource
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == 'success'){
            localStorage.removeItem('customerSource');
            if (!this.appToken){
              this.$router.push('/appointments');
            } else {
              this.loadingCreateAppointment = false
              this.createLinkAppointmentErrorText = 'Запись успешно создана'
              this.createLinkAppointmentDialog = true
            }
          } else {
            if (response.data.errorText){
              this.createLinkAppointmentErrorText = response.data.errorText
            } else {
              this.createLinkAppointmentErrorText = 'Ошибка создании записи'
            }
            this.createLinkAppointmentDialog = true;
            this.loadingCreateAppointment = false
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingCreateAppointment = false
          this.createLinkAppointmentDialog = true
          this.createLinkAppointmentErrorText = 'Ошибка создании записи'
        });
      }
    },
    // Закрыть диалог создания записи по ссылке
    closeCreateDialog(){
      this.createLinkAppointmentDialog = false
    },
    getSrc(userId){
      return (window.config.apiUrl + '/file/get-user-photo?idUser=' + userId)
    },
    shortName(user){
      return (
        user.surname?.length < 13 
        && user.name?.length < 13 
        && user.middleName?.length < 13
        )
    },
    // Создать оплату
    createPayment(){
      if (this.userId && this.date && this.time) {

        if (this.appToken || !this.$store.getters.service || !this.$store.getters.service.length) {
          this.createAppointment()
          return
        } else {

          this.loadingCreateAppointment = true
          let paymentStatusUrl = '';
          if (window.indexPlatform == 'ios'){
            paymentStatusUrl = window.config.url + '/cordova-ios.html'
          }
          if (window.indexPlatform == 'android'){
            paymentStatusUrl = window.config.url + '/cordova-android.html'
          }
          if (window.indexPlatform == 'browser'){
            paymentStatusUrl = window.config.url + '/index.html'
          }
          
          // Создаем счет
          let idsService = this.$store.getters.service ?? [];
          if (!(idsService instanceof Array)) {
            idsService = [idsService];
          }
          idsService = idsService.map( val => val.id);

          const customerSource = localStorage.getItem('customerSource');
          
          this.$http.post(window.config.apiUrl + '/payments/create-payment', {
            services: idsService,
            idUser: this.userId,
            idCustomer: this.$store.getters.activeUser,
            dateFrom: this.date + ' ' + this.time,
            idPayer: this.$store.getters.getUser.id,
            paymentStatusUrl: paymentStatusUrl,
            source: customerSource
          })
          .then((response) => {
            // Если нет ошибок
            if (response.data.result.status == 'success'){
              localStorage.removeItem('customerSource');
              console.log(response.data.result.acquiring);
              // Если услуга требует предоплату
              if (response.data.result.requirePrepaymentInApp){
                // Если форма оплаты получена
                if (response.data.result.acquiring && response.data.result.acquiring.formUrl){
                  // Перенаправляем на форму оплаты
                  window.location = response.data.result.acquiring.formUrl;
                } else {
                  // Запись создана но банк не предоставиль номер заказа
                  this.$router.push('/appointments');
                }
              } 
              else {
                // Если услуга не требует предоплату
                this.loadingCreateAppointment = false
                this.$router.push('/appointments');
              }
            } else {
              console.log(response.data);
              this.createLinkAppointmentErrorText = response.data.result.errorText || response.data.result.error;
              this.createLinkAppointmentDialog = true;
              // Скрываем загрузку услуги
              this.loadingCreateAppointment = false
            }
          })
          .catch((error) => {
            console.log(error);
            this.paymentErrorText = 'Ошибка при создании счета, попробуйте снова';
            this.errorPaymentDialog = true;
          });
        }
      }
    },
  },
}
</script>

<style scoped>
  .appointmet-info-wrapper{
    margin: 0 auto;
    padding: 10px;
  }
  .appointmet-info{
    display: flex;
    justify-content: space-between;
  }
  .appointmet-info.min{
    display: block;
  }
  .appointmet-info .wrap{
    padding: 10px;
  }
  .appointmet-info .icon{
    margin-right: 5px;
    color: #ccc;
  }
  .user-info{
    display: flex;
  }
  .user-info .description{
    padding: 5px;
  }
  .user-info .description span{
    font-size: 00.9rem;
  }
  .avatar{
    padding: 10px;
  }
  .more-info{
    cursor: pointer;
  }
  .more-info p{
    font-size: 0.8rem;
    color: rgb(134, 134, 134);
  }
  .services-select{
    cursor: pointer;
    font-size: 0.9rem;
  }
  .services-list :last-child{
    border: none;
  }
  .services-list-item{
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid #ccc;
    font-size: 0.9rem;
  }
  .services-list-item > *:first-child > *:first-child {
    margin-top: 0;
  }
  .services-list-item:hover{
    color: var(--primary-color);
  }
  .services-list-item.no-service{
    color: #ccc;
  }
  .pl10{
    padding-left: 10px;
  }
</style>