<template>
  <v-card flat class="py-6">
    
    <template v-if="STATIC.bonusRoublesEnable">
      <v-row class="justify-content-center">
        <v-col cols="12" lg="8">
          <div class="fill-height">
            <BonusBalance class="fill-height d-flex justify-content-center align-items-center" />
          </div>
        </v-col>
      </v-row>
    </template>
    
    <template>
      <v-row class="justify-content-center">
        <v-col cols="12" lg="8">
          <v-btn
            block
            depressed
            @click="openLoyaltyProgram()"
          >
            Программа лояльности<v-icon small right>far fa-question-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    
    <template  v-if="STATIC.inviteFriend">
      <v-row class="justify-content-center">
        <v-col cols="12" lg="8">
          <div>
            <InviteFriend />
          </div>
        </v-col>
      </v-row>
    </template>
    
    <template v-if="STATIC.bonusRoublesEnable">
      <v-row class="justify-content-center">
        <v-col cols="12" lg="8">
          <div class="mt-4">
            <h5 class="text-center mb-5">История бонусов</h5>
            <BonusHistory />
          </div>
        </v-col>
      </v-row>
    </template>
    
  </v-card>
</template>

<script>

import BonusHistory from "@/views/Information/BonusHistory";
import InviteFriend from "@/views/Information/InviteFriend";
import BonusBalance from "@/views/Information/BonusBalance";
import { mapGetters } from 'vuex';

export default {
  name: "Cashback",
  
  data: () => ({
    historyLoading: false,
    history: null
  }),
  
  components: {
    BonusHistory,
    InviteFriend,
    BonusBalance
  },
  
  computed: {
    ...mapGetters(['STATIC']),
  },
  
  methods: {
    openLoyaltyProgram() {
      window.open(this.STATIC.loyaltyProgramUrl);
    }
  }
  
}

</script>

<style scoped>

</style>