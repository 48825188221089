<template>
  <div class="wrap" v-if="this.getUser">
    <v-app>
      <div class="app-wrap">
        <Header/>
        <div class="main-container">
          <transition name="router-anim">
            <router-view></router-view>
          </transition>
        </div>
        <FooterMobile/>
        <Footer class="d-none d-lg-block"/>
        <v-dialog v-model="versionDialog" persistent max-width="400">
          <v-card>
            <v-card-title>Личный кабинет</v-card-title>
            <v-card-text>
              <p>Ваша версия приложения устарела и больше не поддерживается. Пожалуйста, обновите приложение до последней версии</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded color="primary" @click="toStore" text>Обновить приложнее</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>

import socket from '@/services/socket.js'
import store from '@/store'
//import eventBus from '../../eventBus';

import Header from '@/views/layouts/Header.vue'
// import HeaderMobile from '@/views/layouts/HeaderMobile.vue'
import FooterMobile from '@/views/layouts/FooterMobile.vue'
import Footer from '@/views/layouts/Footer.vue'
import { mapGetters } from 'vuex'

export default {
  socket: socket,
  name: 'Account',
  components: {
    Header,
    // HeaderMobile,
    FooterMobile,
    Footer,
    // MobileFooter
  },
  data () {
    return {
      versionDialog: false,
      window: {
        width: 0,
        height: 0
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ])
  },
  methods: {
    // Перейти в магазин приложений
    toStore(){
      if ((/Android/i.test(navigator.userAgent))){
        window.open(window.config.store.androidAppUrl, '_blank');
      }
    },
    incomingMessage(result){
      console.log(result);
      this.unreadCount = result.countAll;
    },
    // Оплата новой услуги
    addNewCouponService(idCouponService){
      console.log('pay ' + idCouponService);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
    created() {
      // Подключение peer
      socket.peerConnect();

      // Если пришли с оплаты
      if (this.$route.query.paymentStatus && this.$route.query.orderId){
        let status = this.$route.query.paymentStatus;
        let orderId = this.$route.query.orderId;
        if (status == 'success'){
          this.$router.push('/success-payment?orderId=' + orderId) 
        }
        if (status == 'error'){
          this.$router.push('/error-payment?orderId=' + orderId) 
        }
      }

      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      // Проверяем звук
      var audio = new Audio(require('@/assets/empty.mp3')); 
      audio.play().catch(() => {
        this.noRulesAudio = true;
      });

      // Если мобильное устройство 
      if (/Android/i.test(navigator.userAgent)) {
        // Если не с нового мобильного приложения и не с кордовы
        if (window.indexPlatform == 'browser' && !window.cordova){
          // Показываем диалог обновлении версии
         // this.versionDialog = true;
        }
      } 

      // Подгружаем чат
      this.$http.post(window.config.apiUrl + '/chat/get-coupon-services', {
          'id': store.getters.activeUser
      })
      .then((response) => {
          this.$store.dispatch('setCouponServices', response.data);
          this.servicesLoading = false;
      })
      .catch((error) => {
          console.log(error);
      });

    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
     
    }
}
</script>

<style scoped>

.wrap{
  background: #fff;
  height: 100vh;
}

.app-wrap{
  padding-top: 85px;
  padding-bottom: 60px
}

.content{
    padding: 0;
}

.router-anim-enter-active {
  animation: coming .35s;
  animation-delay: .35s;
  opacity: 0;
}

.router-anim-leave-active {
  animation: going .35s;
}

@keyframes going {
  from {
    transform: translateX(0);
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  90% {
    opacity: 0;
  }
  to {
    transform: translateX(calc(-100vw));
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(calc(100vw));
  }
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  90% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (min-width: 960px) {
  @keyframes going {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
      opacity: 0;
    }
  }
  
  @keyframes coming {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .router-anim-enter-active {
    animation: coming .35s;
    animation-delay: .4s;
    opacity: 0;
  }
  
  .router-anim-leave-active {
    animation: going .35s;
  }
}

</style>
